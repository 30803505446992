import React, {useContext} from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import {graphql} from 'gatsby';

import {LanguageContext} from 'hook/LanguageContext';
import {
    removeNullFromProps,
    convertEnvironmentLivongoLink,
} from 'utils/utilities';

import appStoreDownloadButton from './images/app-store-download-link.png';
import facebookButton from './images/facebook-logo.png';
import instagramButton from './images/instagram-logo.png';
import playStoreDownloadButton from './images/play-store-download-link.png';
import youtubeButton from './images/youtube-logo.png';

import BannerMessage from 'components/bannerMessage';

import * as styles from './Footer.module.scss';

const APP_STORE_LINK = 'https://apps.apple.com/us/app/livongo/id991595088';
const FACEBOOK_LINK = 'https://www.facebook.com/livongo/';
const INSTAGRAM_LINK = 'https://www.instagram.com/livongo_health/';
const PLAY_STORE_LINK =
    'https://play.google.com/store/apps/details?id=com.livongo.lvmobility&hl=en_US&gl=US';
const YOUTUBE_LINK = 'https://www.youtube.com/user/livongo';

/* 
    {
        name: Display Text
        link: The url to be redirect to. A url like "/diabetes" will be converted to 
            homepages.integration.livongo.com/diabetes based on the environment.
        newTab: A boolean that will determine if the url is opened in a new tab or not.  Default is false.
        convertEnvironment: Setting this to true will make sure the livongo.com url will be converted by 
            convertEnvironmentLivongoLink. i.e https://content.livongo.com.  Default is false
    }
*/

const Footer = props => {
    const {
        aboutLinks,
        disclaimer,
        floatingLinks,
        supportLinks,
        plNumber,
        programLinks,
        footerBanner,
        ref,
    } = removeNullFromProps(props);
    const {languageText} = useContext(LanguageContext);

    return (
        <footer role="contentinfo" className={classNames(styles.footer)}>
            {/* Rainbow Border Start */}
            <table className={styles.rainbowBorder} aria-hidden="true">
                <tbody>
                    <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                    </tr>
                </tbody>
            </table>

            {/* Rainbow Border End */}
            <div className={classNames(styles.container)}>
                <div className={styles.extole}>
                    {footerBanner && (
                        <BannerMessage
                            ref={ref}
                            {...footerBanner}
                            displayExtoleOn={'footer'}
                        />
                    )}
                </div>
                <div className={styles.linkContainer}>
                    <div className={styles.linkColumn}>
                        <div className={styles.subtitle}>
                            {languageText['learn-more']}
                        </div>
                        {programLinks &&
                            programLinks.map(obj => (
                                <a
                                    key={obj.name}
                                    className={styles.footerColumnLink}
                                    href={
                                        obj.convertEnvironment
                                            ? convertEnvironmentLivongoLink(
                                                  obj.link
                                              )
                                            : obj.link
                                    }
                                    target={obj.newTab ? '_blank' : null}
                                    rel="noreferrer"
                                >
                                    {obj.name}
                                </a>
                            ))}
                    </div>
                    <div className={styles.linkColumn}>
                        <div className={styles.subtitle}>
                            {languageText['about']}
                        </div>
                        {aboutLinks &&
                            aboutLinks.map(obj => (
                                <a
                                    key={obj.name}
                                    className={styles.footerColumnLink}
                                    href={
                                        obj.convertEnvironment
                                            ? convertEnvironmentLivongoLink(
                                                  obj.link
                                              )
                                            : obj.link
                                    }
                                    target={obj.newTab ? '_blank' : null}
                                    rel="noreferrer"
                                >
                                    {obj.name}
                                </a>
                            ))}
                    </div>
                    <div className={styles.linkColumn}>
                        <div className={styles.subtitle}>
                            {languageText['support']}
                        </div>
                        {supportLinks &&
                            supportLinks.map(obj => (
                                <a
                                    key={obj.name}
                                    className={styles.footerColumnLink}
                                    href={
                                        obj.convertEnvironment
                                            ? convertEnvironmentLivongoLink(
                                                  obj.link
                                              )
                                            : obj.link
                                    }
                                    target={obj.newTab ? '_blank' : null}
                                    rel="noreferrer"
                                >
                                    {obj.name}
                                </a>
                            ))}
                    </div>
                    <div className={styles.linkColumn}>
                        <div className={styles.subtitle}>
                            {languageText['connect-with-us']}
                        </div>
                        <div className={styles.socialLinkContainer}>
                            <div className={styles.row}>
                                <a href={APP_STORE_LINK} target="_blank">
                                    <img
                                        src={appStoreDownloadButton}
                                        className={styles.rightSpacer}
                                        height={34}
                                        width={100}
                                        aria-label="Apple App Store app link"
                                        alt=""
                                    />
                                </a>

                                <a href={PLAY_STORE_LINK} target="_blank">
                                    <img
                                        src={playStoreDownloadButton}
                                        height={34}
                                        width={100}
                                        aria-label="Google Play Store app link"
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div
                                className={classNames(
                                    styles.row,
                                    styles.socialMediaRow
                                )}
                            >
                                <a href={FACEBOOK_LINK} target="_blank">
                                    <img
                                        src={facebookButton}
                                        className={styles.iconSpacer}
                                        width={50}
                                        aria-label="Facebook page link"
                                        alt=""
                                    />
                                </a>
                                <a href={INSTAGRAM_LINK} target="_blank">
                                    <img
                                        src={instagramButton}
                                        className={styles.iconSpacer}
                                        width={50}
                                        aria-label="Instagram page link"
                                        alt=""
                                    />
                                </a>

                                <a href={YOUTUBE_LINK} target="_blank">
                                    <img
                                        src={youtubeButton}
                                        className={styles.iconSpacer}
                                        width={50}
                                        aria-label="Youtube page link"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        {disclaimer && (
                            <div className={styles.disclaimer}>
                                {renderRichText(disclaimer)}
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.legalContainer}>
                    <div className={styles.legalLinkContainer}>
                        {floatingLinks &&
                            floatingLinks.map(link => (
                                <a
                                    key={link.name}
                                    href={link.link}
                                    className={styles.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {link.name}
                                </a>
                            ))}
                    </div>
                    <div>
                        <span>
                            {languageText['teladoc-health-inc'] +
                                ' ' +
                                plNumber}
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

export const query = graphql`
    fragment Footer on ContentfulFooter {
        programLinks {
            name
            link
            newTab
            convertEnvironment
        }
        aboutLinks {
            name
            link
            newTab
            convertEnvironment
        }
        supportLinks {
            name
            link
            newTab
            convertEnvironment
        }
        floatingLinks {
            name
            link
            newTab
            convertEnvironment
        }
        footerBanner {
            name
            backgroundColorHex
            textColorHex
            content {
                raw
            }
            ctaText
            ctaLink
            ctaColorHex
            stickToTop
            icon {
                ...CloudinaryImage
            }
        }
        disclaimer {
            raw
        }
    }
`;
