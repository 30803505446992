import each from 'lodash/each';
import isArray from 'lodash/isArray';
import mixpanel from 'mixpanel-browser';

const makeCall = ({event, properties}) => {
    mixpanel.track(event, properties);
};

/**
 * Utilities for working with Mixpanel.
 * @module MixpanelUtils
 */
const MixpanelUtils = {
    /**
     * Identifies a user with a unique ID.
     * @static
     * @function identify
     * @param {String} uniqueId - The unique identifier.
     * @example
     * MixpanelUtils.identify(uuidV4());
     * @returns {void}
     */
    identify(uniqueId) {
        mixpanel.identify(uniqueId);
    },

    /**
     * Initializes a new instance of the Mixpanel tracking object.
     * @static
     * @function init
     * @param {String} token - The Mixpanel API token.
     * @param {Object} [data={}] - Data to pass to the Mixpanel init call.
     * @param {Object} [data.config] - A dictionary of config options to override. See <a href="https://github.com/mixpanel/mixpanel-js/blob/8b2e1f7b/src/mixpanel-core.js#L87-L110">default options</a>.
     * @param {Object} [data.name] - A name for the new Mixpanel instance.
     * @example
     * MixpanelUtils.init('ABCDEFG');
     * @returns {void}
     */
    init(token, {config, name} = {}) {
        mixpanel.init(token, config, name);

        window.mixpanel = mixpanel;
    },

    /**
     * Clears super properties and generates a new random distinct ID for this instance. Useful for clearing data when a user logs out.
     * @static
     * @function reset
     * @example
     * MixpanelUtils.reset();
     * @returns {void}
     */
    reset() {
        mixpanel.reset();
    },

    /**
     * Register a set of super properties, which are included with all events.
     * @static
     * @function setSuperProps
     * @param {Object} props - An associative array of properties to store about the user.
     * @example
     * MixpanelUtils.setSuperProps({Email: 'test@livongo.com'});
     * @returns {void}
     */
    setSuperProps(props) {
        mixpanel.register(props);
    },

    /**
     * Register a set of super properties only once, which are included with all events.
     * @static
     * @function setSuperPropsOnce
     * @param {Object} props - An associative array of properties to store about the user.
     * @example
     * MixpanelUtils.setSuperPropsOnce({Email: 'test@livongo.com'});
     * @returns {void}
     */
    setSuperPropsOnce(props) {
        mixpanel.register_once(props);
    },

    /**
     * Set properties on a user record.
     * @static
     * @function setPeopleProps
     * @param {Object} props - An associative array of properties to store about the user.
     * @example
     * MixpanelUtils.setPeopleProps({'First name': 'John', Email: 'test@livongo.com'});
     * @returns {void}
     */
    setPeopleProps(props) {
        mixpanel.people.set(props);
    },

    /**
     * Set properties on a user record only once.
     * @static
     * @function setPeoplePropsOnce
     * @param {Object} props - An associative array of properties to store about the user.
     * @example
     * MixpanelUtils.setPeoplePropsOnce({'First name': 'John', Email: 'test@livongo.com'});
     * @returns {void}
     */
    setPeoplePropsOnce(props) {
        mixpanel.people.set_once(props);
    },

    /**
     * Track an event.
     * @static
     * @function track
     * @param {Array|Object} data - An array of or a single object of events to track.
     * @param {String} data.event - The name of the event.
     * @param {Object} [data.properties] - A set of properties to include with the event.
     * @example
     * MixpanelUtils.track({
     *     event: 'User Landed',
     *     properties: {
     *         'From Screen': 'Home
     *     }
     * });
     * @returns {void}
     */
    track(data) {
        if (isArray(data)) {
            each(data, trackingCall => {
                makeCall(trackingCall);
            });
        } else {
            makeCall(data);
        }
    },
};

export default MixpanelUtils;
