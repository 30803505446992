import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {LanguageContext} from 'hook/LanguageContext';
import {translationClicked} from 'utils/mixpanel-utils';
import {
    convertEnvironmentLivongoLink,
    getCloudinaryObject,
    removeNullFromProps,
} from 'utils/utilities';

import CTAButton from 'components/ctaButton';

import * as styles from './Header.module.scss';
const LIVONGO_LOGO =
    'https://assets.livongo.com/image/upload/v1641508544/landing-pages/header-block/livongoTH_logo.png';
const LOCATION_ICON =
    'https://assets.livongo.com/image/upload/v1644262366/home-pages/placeholders/location.png';
const LANG_DICTIONARY = {
    'en-US': 'English',
    'es-US': 'Español',
};

const Header = props => {
    const {ctaLogin, ctaJoinNow, cobrandLogo, isMobile} = removeNullFromProps(
        props
    );
    const homepageUrl = convertEnvironmentLivongoLink('/');
    const [showStickyHeader, setStickyHeader] = useState(false);
    const CONTACT_US = convertEnvironmentLivongoLink('/contact-us');

    const {
        languageText,
        availableLanguages,
        language,
        updateCurrentLang,
    } = useContext(LanguageContext);

    const cobrandLogoObj = getCloudinaryObject(cobrandLogo);
    const stickyHeader = e => {
        let currentY = e.currentTarget.scrollY;

        if (currentY > 70 && !showStickyHeader) {
            setStickyHeader(true);
        } else if (currentY <= 70 && showStickyHeader) {
            setStickyHeader(false);
        } else {
            setStickyHeader(false);
        }
    };

    const headerClasses = classNames(styles.header, {
        [styles.sticky]: showStickyHeader,
    });

    const onClickTranslate = lang => {
        translationClicked();
        updateCurrentLang(lang); //update lang throughout the pages
    };

    useEffect(() => {
        window.addEventListener('scroll', e => stickyHeader(e));

        return () => {
            window.removeEventListener('scroll', e => stickyHeader(e));
        };
    }, []);

    return (
        <header className={headerClasses}>
            <div className={classNames(styles.logoContentItem)}>
                <a href={homepageUrl}>
                    <img
                        className={styles.lvLogo}
                        src={LIVONGO_LOGO}
                        alt="Livongo Logo"
                    />
                </a>

                {cobrandLogoObj && (
                    <img
                        className={styles.coBrandingLogo}
                        src={cobrandLogoObj?.url}
                        alt={cobrandLogoObj?.name}
                    />
                )}
            </div>

            <div className={styles.btnsContentItem}>
                {availableLanguages.length === 0 ||
                (availableLanguages.length === 1 &&
                    availableLanguages[0] === 'en-US') ? (
                    <></>
                ) : (
                    <label className={styles.dropDown}>
                        <img
                            className={styles.locationIcon}
                            src={LOCATION_ICON}
                            alt="LOCATION ICON"
                        />

                        <div className={styles.btn}>
                            {LANG_DICTIONARY[language]}
                        </div>

                        <input
                            type="checkbox"
                            name="checkbox"
                            className={styles.toggleLang}
                        />

                        <ul className={styles.lang}>
                            {availableLanguages &&
                                availableLanguages.map((lang, i) => {
                                    return (
                                        <li
                                            key={i}
                                            onClick={() => {
                                                onClickTranslate(lang);
                                            }}
                                        >
                                            {LANG_DICTIONARY[lang]}
                                        </li>
                                    );
                                })}
                        </ul>

                        <div className={styles.arrowDown}></div>
                    </label>
                )}

                {/* {!isMobile && (
                    <a href={`${CONTACT_US}`} className={styles.contactUs}>
                        {languageText['contact-us']}
                    </a>
                )} */}

                {ctaLogin && (
                    <CTAButton
                        id="ctaHeaderLogin"
                        className={styles.login}
                        {...ctaLogin}
                    />
                )}

                {ctaJoinNow && (
                    <CTAButton
                        id="ctaHeaderJoinNow"
                        className={styles.joinNow}
                        {...ctaJoinNow}
                    />
                )}
            </div>
        </header>
    );
};

Header.propTypes = {
    ctaLogin: PropTypes.object,
    ctaJoinNow: PropTypes.object,
    isMobile: PropTypes.bool,
};

export default Header;
