// extracted by mini-css-extract-plugin
export var container = "Footer-module--container--1cVSm";
export var disclaimer = "Footer-module--disclaimer--ADGrd";
export var extole = "Footer-module--extole--55yK2";
export var footer = "Footer-module--footer--iTLBh";
export var footerColumnLink = "Footer-module--footer-column-link--z3NVE";
export var iconSpacer = "Footer-module--icon-spacer--qoA6b";
export var legalContainer = "Footer-module--legal-container---0Rd9";
export var legalLinkContainer = "Footer-module--legal-link-container--+MLlR";
export var link = "Footer-module--link--UaI6A";
export var linkColumn = "Footer-module--link-column--e2pPv";
export var linkContainer = "Footer-module--link-container--15Xts";
export var rainbowBorder = "Footer-module--rainbow-border--ldqvV";
export var rightSpacer = "Footer-module--right-spacer--kPQa3";
export var row = "Footer-module--row--0+ol8";
export var socialLinkContainer = "Footer-module--social-link-container--cg4rY";
export var socialMediaRow = "Footer-module--social-media-row--BOZtR";
export var subtitle = "Footer-module--subtitle--Xq55F";