import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {GlobalContext} from 'hook/GlobalContext';
import {ctaClicked} from 'utils/mixpanel-utils';
import {
    convertEnvironmentLivongoLink,
    removeNullFromProps,
} from 'utils/utilities';

import * as styles from './CTAButton.module.scss';

const REG_5_URL = 'https://signup.livongo.com';
const BUTTON_COLORS = {
    WHITE: 'WHITE',
    PURPLE: 'PURPLE',
};

const BUTTON_SIZES = {
    SMALL: 'SMALL',
    NORMAL: 'NORMAL',
    LARGE: 'LARGE',
};

const CTAButton = props => {
    const {
        className,
        buttonText,
        backgroundColor = BUTTON_COLORS.PURPLE,
        size = BUTTON_SIZES.SMALL,
        url = REG_5_URL,
        id,
        buttonType,
    } = removeNullFromProps(props);
    const {ccid} = useContext(GlobalContext);
    var targetElement;

    if (typeof window !== 'undefined') {
        targetElement = document.getElementById('VIDEO_BLOCK');
    }

    const classes = classNames(styles.ctaButton, className, {
        [styles.white]: backgroundColor === BUTTON_COLORS.WHITE,
        [styles.purple]: backgroundColor === BUTTON_COLORS.PURPLE,
        [styles.small]: size === BUTTON_SIZES.SMALL,
        [styles.normal]: size === BUTTON_SIZES.NORMAL,
        [styles.large]: size === BUTTON_SIZES.LARGE,
    });

    const onClickCTA = () => {
        let newURL = convertEnvironmentLivongoLink(url, ccid);

        if (!newURL) {
            return;
        }

        trackMixpanel();
        trackUET();
        trackTikTok();
        trackFacebookPixel();
        trackGoogleTagManager(newURL);

        window.location = newURL;
    };

    const trackMixpanel = () => {
        ctaClicked();
    };

    const trackUET = () => {
        if (window.uetq) {
            window.uetq.push('event', {});
        }
    };

    const trackTikTok = () => {
        if (window.ttq) {
            window.ttq.track('ClickButton', {
                button_name: buttonText,
            });
        }
    };

    const trackFacebookPixel = () => {
        if (typeof fbq === 'function') {
            fbq('track', 'Lead');
        }
    };

    const trackGoogleTagManager = newURL => {
        if (typeof gtag === 'function') {
            gtag('event', 'conversion', {
                send_to: process.env.GOOGLE_ADS_TOKEN + '/ASW_COCz-e8BEL3opMID',
                event_callback: () => {
                    window.location = newURL;
                },
            });
        }
    };

    const onScrollButtonClick = () => {
        targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    return buttonType === 'scroll' ? (
        <button
            id={id}
            data-test-id="join-now-button"
            className={classes}
            onClick={onScrollButtonClick}
        >
            {buttonText}
        </button>
    ) : (
        <button id={id} className={classes} onClick={onClickCTA}>
            {buttonText}
        </button>
    );
};

CTAButton.propTypes = {
    className: PropTypes.string,
    buttonText: PropTypes.string,
    backgroundColor: PropTypes.string,
    size: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    buttonType: PropTypes.string,
};

export default CTAButton;
