// extracted by mini-css-extract-plugin
export var arrowDown = "Header-module--arrow-down--r+qWQ";
export var btn = "Header-module--btn--uQLuj";
export var btnsContentItem = "Header-module--btns-content-item--2cmW3";
export var coBrandingLogo = "Header-module--co-branding-logo--TIKAr";
export var contactUs = "Header-module--contact-us--Fdgtk";
export var dropDown = "Header-module--drop-down--WAOQz";
export var header = "Header-module--header--Wcvsx";
export var joinNow = "Header-module--join-now--uVbXi";
export var lang = "Header-module--lang--sYUJJ";
export var locationIcon = "Header-module--location-icon--KHOFI";
export var login = "Header-module--login--V4CBk";
export var logoContentItem = "Header-module--logo-content-item--7APal";
export var lvLogo = "Header-module--lv-logo--xP9LT";
export var promo = "Header-module--promo--0RSfQ";
export var sticky = "Header-module--sticky--f3gij";
export var toggleLang = "Header-module--toggle-lang--9GSSs";